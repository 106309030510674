export default {
    namespace: "true",
    state() {
        return {
            userid: "",
            toLogin: true,
            ifLogin: false,
            menu_list: [],
            iframe_url: "",
            tabs: [],
            notice: [],
            userInfo: {},
            updated: true,
            status: false,
            time: "",
            pages_url: ""
        }
    },
    actions: {
        setPagesUrl({ commit }, val) {
            commit("SETPAGESURL", val)
        },
        setTime({ commit }, val) {
            commit("SETTIME", val)
        },
        setStatus({ commit }, val) {
            commit("SETSTATUS", val)
        },
        setUpdate({ commit }, bool) {
            commit("SETUPDATE", bool)
        },
        setUserId(str) {
            this.userid = str
        },
        setToLogin({ commit }, bool) {
            commit("setLogin", bool)
        },
        getIfLogin({ commit }, val) {
            commit("setIfLogin", val)

        },
        setMenuAct({ commit }, arr) {
            commit("setMenuMuta", arr)
        },
        setIframeUrl({ commit }, url) {
            commit("setUrl", url)
        },
        setTabs({ commit }, arr) {
            commit("SETTABS", arr)
        },
        setNotice({ commit }, arr) {
            commit("SETNOTICE", arr)
        },
        setUserInfo({ commit }, obj) {
            commit("SETUSERINFO", obj)
        }
    },
    mutations: {
        SETPAGESURL(state, val) {
            state.pages_url = val
        },
        SETTIME(state, val) {
            state.time = val
        },
        SETSTATUS(state, val) {
            state.status = val
        },
        SETUPDATE(state, bool) {
            state.updated = bool
        },
        SETUSERINFO(state, val) {
            state.userInfo = val
        },
        SETNOTICE(state, val) {
            state.notice = val
        },
        SETTABS(state, val) {
            state.tabs = val
        },
        setLogin(state, val) {
            state.toLogin = val
        },
        setIfLogin(state, val) {
            state.ifLogin = val
        },
        setMenuMuta(state, val) {
            state.menu_list = val
        },
        setUrl(state, val) {
            state.iframe_url = val
        }
    }

}
