export default {
    namespace: "true",
    state() {
        return {
            has: true,
            isHtm: false,
            appMenu: false
        }
    },
    actions: {
        setHas({ commit }, val) {
            commit("SETHAS", val)
        },
        setHtm({ commit }, val) {
            commit("SETHTM", val)
        },
        setAppMenu({ commit }, val) {
            commit("SETAPPMENU", val)
        }
    },
    mutations: {
        SETHAS(state, val) {
            state.isHome = val
        },
        SETHTM(state, val) {
            state.isHtm = val
        },
        SETAPPMENU(state, val) {
            state.appMenu = val
        }
    }

}