import { h } from 'vue';
import {
    ContactsOutlined, UserOutlined, HomeOutlined, SettingOutlined,
    PieChartOutlined, BarChartOutlined, ReconciliationOutlined, AccountBookOutlined,
    ContainerOutlined, AppstoreOutlined, BellOutlined, FormOutlined, ProjectOutlined
} from "@ant-design/icons-vue"

let menu_list =
{
    "HomeOutlined": () => h(HomeOutlined),
    "ContactsOutlined": () => h(ContactsOutlined),
    "UserOutlined": () => h(UserOutlined),
    "AppstoreOutlined": () => h(AppstoreOutlined),
    "ContainerOutlined": () => h(ContainerOutlined),
    "AccountBookOutlined": () => h(AccountBookOutlined),
    "ReconciliationOutlined": () => h(ReconciliationOutlined),
    "BarChartOutlined": () => h(BarChartOutlined),
    "PieChartOutlined": () => h(PieChartOutlined),
    "SettingOutlined": () => h(SettingOutlined),
    "BellOutlined": () => h(BellOutlined),
    "FormOutlined": () => h(FormOutlined),
    "ProjectOutlined": () => h(ProjectOutlined)
}


export { menu_list }