<template>
    <a-drawer v-model:open="todo"
        :rootStyle="{ height: 'calc(100% - var(--menu-head-height))', marginTop: 'var(--menu-head-height)', }"
        width="calc(100% - var(--menu-width))" :contentWrapperStyle="{ boxShadow: '0 0px 0px #ccc' }"
        :bodyStyle="{ padding: 0, paddingTop: '25px', }" :closable="false" :keyboard="true" :mask="false"
        placement="right" @close="onClose" :destroyOnClose="true">
        <!-- <template #extra>
            <a-space>
                <a-button @click="onClose">返回</a-button>
            </a-space>
        </template> -->
        <div class="drawerbox">
            <div class="menubox">
                <!-- <a-menu style="margin-top: 8px;"> -->
                <div class="menu_item" @click="itemClick(index)" v-for="(item, index) in items" :key="item.key">
                    <span>{{ item.title }}</span>
                    <p v-if="getCount(item)">{{ getCount(item) }}</p>
                </div>
                <!-- </a-menu> -->
            </div>
            <div class="content">
                <div class="cont_head">
                    <div class="title">
                        <span>{{ title }}</span>
                        <span>
                            <CloseOutlined @click="onClose" class="close" />
                        </span>
                    </div>
                    <div class="headTabs"><a-tabs v-model:activeKey="activeKey" @change="changeTabs">
                            <a-tab-pane v-for="i in tabs" :key="i.key" :tab="i.title"></a-tab-pane>
                        </a-tabs></div>
                </div>
                <!-- <div class="todospin" v-if="iframe_loading"> <a-spin size="large" :spinning="iframe_loading" /> -->
                <!-- </div> -->
                <iframe class="todoIframe" :allowfullscreen="true" id="todoIframe" :src="iframe_url"
                    frameborder="0"></iframe>
            </div>
        </div>
    </a-drawer>
</template>
<script setup>
import { onMounted, computed, watch, ref, } from "vue"
import { CloseOutlined } from "@ant-design/icons-vue"
import { useStore } from 'vuex'

const store = useStore()
let iframe_loading = ref(true)
let title = ref(null)
let items = computed(() => store.state.head.inline_menu)
const activeKey = ref(null)
const todo = computed(() => store.state.head.toDo)
let news = computed(() => store.state.head.news)
const onClose = () => {
    store.dispatch("setTodo", false)
}
const getCount = (item) => {
    let n = news.value
    let o = item.order
    if (o === 1) {
        return n.busi
    }
    if (o === 3) {
        return n.order
    }
    if (o === 5) {
        return n.clue
    }
    if (o === 6) {
        return n.foll_busi
    }
}
let iframe_url = ref("")
const changeTabs = (key) => {
    iframe_url.value = key
    // console.log(key);
    iframe_loading.value = true
    // checkLoad()
}
let tabs = ref([])
const checkLoad = () => {
    const iframe = document.querySelector('#todoIframe')
    // 处理兼容行问题 兼容IE
    if (iframe.attachEvent) {
        iframe.attachEvent('onload', function () {
            // iframe加载完毕以后执行操作
            // console.log('iframe已加载完毕')
            setTimeout(function () {
                iframe_loading.value = false
            }, 400)
        })
    } else {
        iframe.onload = function () {
            // iframe加载完毕以后执行操作
            // console.log('iframe已加载完毕1')
            setTimeout(function () {
                iframe_loading.value = false
            }, 400)
        }
        iframe.onerror = function () {
            console.log("iframe 加载错误");
        };
    }
}
const itemClick = (e) => {
    iframe_loading.value = true
    title.value = items.value[e].title
    tabs.value = items.value[e].tabs
    iframe_url.value = tabs.value[0].key
    // checkLoad()
    activeKey.value = tabs.value[0].key
    let eleitems = document.getElementsByClassName("menu_item")
    for (let i = 0; i < eleitems.length; i++) {
        if (i === e) {
            eleitems[i].classList.add("select")
        } else {
            if (Array.from(eleitems[i].classList).includes("menu_item")) {
                eleitems[i].classList.remove("select")
            }
        }
    }
}
onMounted(() => {
    watch(todo, (newVal) => {
        if (newVal) {
            setTimeout(function () { itemClick(0) }, 1)
        }
    })
})
</script>
<style scoped>
.menu_item>span {
    width: 140px;
}

.menu_item>p {
    width: 20px !important;
    height: 20px;
    background: rgba(227, 77, 89, 1);
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    text-align: center;
    margin-top: 10px;
}

.close {
    cursor: pointer;
    font-size: 20px;
}

.select {
    background: rgb(235, 235, 235);

}

.drawerbox {
    width: 100%;
    height: 100%;
    position: relative;
}

.menubox {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 210px;
    border-right: 1px solid #f0f0f0;
}

.menubox>div {
    width: 95%;
    height: 40px;
    margin-left: 2.5%;
    margin-top: 5px;
    padding: 5px 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: start;
    align-items: center;
    border-radius: 5px;
}

.menubox>div:hover {
    cursor: pointer;
    /* background: rgba(248, 248, 248, .7); */
}

.content {
    position: absolute;
    left: 210px;
    top: 0;
    width: calc(100% - 210px);
    height: calc(100% - 120px);
}

.cont_head {
    width: 100%;
    height: 110px;
    display: flex;
    align-items: start;
    flex-direction: column;
    /* background-color: #7f7474; */
}

.title {
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: space-between;
    padding: 5px 15px 0 24px;
    box-sizing: border-box;
}

.title>span:nth-child(1) {
    font-size: 30px;
    font-weight: 400;
}

.title>span:nth-child(2) {
    margin-top: 8px;
    margin-right: 25px
}

.todoIframe {
    width: 100%;
    position: absolute;
    right: 0;
    top: 71px;
    height: calc(100% + (110px - 71px))
}

.ant-drawer>.ant-drawer-right>.ant-drawer-content-wrapper {
    box-shadow: 0 0px 0px #ccc !important;
}

.headTabs {
    margin-top: 15px;
    padding: 0 24px;
    box-sizing: border-box;
    z-index: 88;
    background-color: #fff;
    width: 100%;
}

.todospin {
    position: absolute;
    left: 0;
    top: 76px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>