import { createStore } from 'vuex'
import home from "./module/home";
import head from "./module/head";
import menu from "./module/menu";
import proj from "./module/proj"

export default createStore({
    state: {
    },
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        /**
         * app全局参数
         */
        home,
        head,
        menu,
        proj
    }
})

