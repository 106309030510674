<template>
  <Transition name="slide-fade">
    <div class="menu" v-if="ifLogin && updated" ref="menu">
      <Menu></Menu>
    </div>
  </Transition>
  <div class="head" v-if="ifLogin && updated" ref="head">

    <Head></Head>

  </div>
  <div :class="ifLogin && updated ? 'home' : 'login'">
    <RouterView v-slot="{ Component }">
      <transition name="slide-route">
        <component :is="Component" />
      </transition>
    </RouterView>
  </div>

</template>

<script>

import Menu from './views/Menu'
import Head from './views/Head'
import { ref, computed } from "vue"
import { useStore } from "vuex"

export default {
  name: 'App',
  setup() {
    let store = useStore()
    let ifLogin = computed(() => store.state.home.ifLogin)
    let updated = computed(() => store.state.home.updated)
    let menu = ref()
    let head = ref()
    return { menu, head, ifLogin, store, updated }
  },
  components: {
    Menu,
    Head
  },
  data() {
    return {

    }
  },
  methods: {

  },
  mounted() {


  }
}
</script>
<style>
@import "./index.css";
</style>


<style>
.slide-fade-enter-active,
.slide-top-enter-active,
.slide-route-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active,
.slide-top-leave-active,
.slide-route-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-210px);
  opacity: 0;
}

.slide-route-enter-from,
.slide-route-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.slide-top-enter-from,
.slide-top-leave-to {
  transform: translateY(-210px);
  opacity: 0;
}
</style>
