import { createApp, computed } from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import router from './router'
import store from "./store/index"
import 'ant-design-vue/dist/reset.css';
import NProgress from './utils/nprogress';
import 'nprogress/nprogress.css';
import { ifLoginfn, decrypto, conectToSocket, getUrlSearch } from "./apis/index"

const app = createApp(App);
// conectToSocket()
const updated = computed(() => store.state.home.updated)
let done = false
router.beforeEach((to, from, next) => {
    if (JSON.stringify(to.query) !== "{}") {
        localStorage.setItem("query", JSON.stringify(to.query))
    }
    // 处理传入表格链接进入pages页面
    try {
        let url = getUrlSearch("url")
        let file_name = getUrlSearch("file_name")
        if (url) {
            // console.log(url);
            localStorage.setItem("iframe_url", url)
        }
        if (to.query.file_name) {
            localStorage.setItem("file_name", file_name)
        }
    } catch (error) {
        console.log(error);
    }

    // NProgress.start()
    if (to.meta.title) {
        document.title = to.meta.title
    }
    // 只第一次进入系统时链接websocket
    if (!done) {
        conectToSocket().then(() => {
            things()
            done = true
        })
    } else {
        things()
    }
    function things() {
        //  更新状态
        if (!updated.value) {
            if (to.path === "/update") {
                next()
            } else {
                router.push("/update")
            }
            // 若没有从socket中传来更新的时间则正常执行
        } else if (updated.value === true) {
            // checkIfHome控制tabs栏的隐藏与显示
            checkIfHome(to, store)
            if (to.path === "/login") {
                store.dispatch("setToLogin", true)
                localStorage.setItem("menu", JSON.stringify([]))
                store.dispatch("getIfLogin", false)
                localStorage.clear("token")
                next()
            } else if (to.path === "/update") {
                router.push("/")
            } else {

                // 每次进入路由都判断一次是否登录
                store.dispatch("setToLogin", false)
                ifLoginfn().then(res => {
                    if (to.path === "/download") {
                        store.dispatch("getIfLogin", false)
                    } else {
                        if (res * 1 === 1 && localStorage.getItem("token")) {
                            store.dispatch("setUserInfo", localStorage.getItem("token") ? decrypto(localStorage.getItem("token")) : "")
                            store.dispatch("getIfLogin", true)
                        } else {
                            store.dispatch("getIfLogin", false)
                            localStorage.clear("token")
                            router.push("/login")
                        }
                    }

                    next()
                })
            }
        }
    }

})
router.afterEach((to) => {
    sessionStorage.setItem("route", to.path)
    // NProgress.done()
})
app.use(Antd).use(router).use(store).mount('#app');
function checkIfHome(to, store) {
    let path = to.path
    if (path === "/home") {
        store.dispatch("setIsHome", true)
    } else {
        store.dispatch("setIsHome", false)
    }
}

