<template>
    <div class="null_content">
        <a-carousel class="cont-carousel" v-show="notice_list.length > 0" dot-position="left" autoplay :dots="false">
            <div class="notice" v-for="(item, index) in notice_list" :key="index">
                <SoundTwoTone /> &nbsp;&nbsp;&nbsp;{{ item }}
            </div>
        </a-carousel>
    </div>
    <Transition name="slide-head">
        <div>
            <div class="appMenu">
                <!-- <a-anchor direction="horizontal" @click="anchorClick" :items="tabs"></a-anchor> -->
                <span class="appMenuBtn" @click="openAppMenu">
                    <AlignLeftOutlined />&nbsp;&nbsp;菜单
                </span>
                <a-divider type="vertical" />
            </div>
            <div class="bread" v-if="isHome">
                <!-- <a-anchor direction="horizontal" @click="anchorClick" :items="tabs"></a-anchor> -->
                <a-tabs @change="changeFrameUrl" v-model:activeKey="selectedKey">
                    <a-tab-pane v-for="item in tabs" :key="item.key" :tab="item.title"></a-tab-pane>
                </a-tabs>
            </div>
        </div>

    </Transition>
    <div class="logOut">
        <a-space :size="26">
            <div v-for="item in head_menu">
                <a-tooltip placement="bottom">
                    <template #title>
                        <span>{{ item.title }}</span>
                    </template>
                    <div class="headiconbox">
                        <a-badge :count="getNewSum()" v-if="checkIsIcon(item, 'BellOutlined')"
                            @click="toDoThings(item)">
                            <BellOutlined v-if="checkIsIcon(item, 'BellOutlined')" class="headicon" />
                        </a-badge>
                        <FormOutlined v-if="checkIsIcon(item, 'FormOutlined')" class="headicon"
                            @click="toCalendar(item.key)" />
                        <NodeIndexOutlined v-if="checkIsIcon(item, 'NodeIndexOutlined')" class="headicon"
                            @click="toClue(item.key)" />
                    </div>
                </a-tooltip>
            </div>
            <a-dropdown trigger="click">
                <a-avatar style="margin-bottom: 3px;cursor: pointer;" :size="31" :alt="userInfo.nickname"
                    :src="userInfo.avatar_url"></a-avatar>
                <template #overlay>
                    <a-menu>
                        <a-menu-item>
                            <a class="ant-dropdown-link" @click="changeAccount">
                                <SwapOutlined /> 切换账号
                            </a>
                        </a-menu-item>
                        <a-menu-item @click="reLogin">
                            <LoginOutlined /> 退出登录
                        </a-menu-item>
                    </a-menu>
                </template>
            </a-dropdown>
        </a-space>
        <!-- <span v-if="device">
            <AlignRightOutlined />
        </span> -->
    </div>
    <ToDoThings></ToDoThings>

</template>
<script setup>
import { onMounted, h, computed, ref, watch } from "vue"
import { SoundTwoTone, SwapOutlined, LoginOutlined, FormOutlined, BellOutlined, NodeIndexOutlined, AlignRightOutlined, AlignLeftOutlined } from "@ant-design/icons-vue"
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { post } from "@/utils/http"
import ToDoThings from "@/components/ToDoThings.vue"
import { decrypto } from "../apis/index"

const isHome = computed(() => store.state.head.isHome)
const news = computed(() => store.state.head.news)
const store = useStore()
const router = useRouter()
let device = ref(false)//false为pc，true为移动端
const tabs = computed(() => store.state.home.tabs)
const toCalendar = (key) => {
    store.dispatch("setTodo", false)
    let path = router.currentRoute.value.fullPath
    if (path !== "/home") {
        router.push("/home")
    }
    setStorage("selectedKey", key)
    let arr = [{
        href: key, key, title:
            "新建销售活动"
    }]
    store.dispatch("setTabs", arr)
    setStorage("tabs", JSON.stringify(arr))
    store.dispatch("setIframeUrl", key)

}
const openAppMenu = () => {
    store.dispatch("setAppMenu", true)
}
let head_menu = computed(() => store.state.head.menu)
const toDo = computed(() => store.state.head.toDo)
const toDoThings = (item) => {
    store.dispatch("setTodo", !toDo.value)
    store.dispatch("setInlineMenu", item.children)
}
const toClue = (key) => {
    store.dispatch("setTodo", false)
    let path = router.currentRoute.value.fullPath
    if (path !== "/home") {
        router.push("/home")
    }
    let arr = [{
        href: key, key, title:
            "新建线索"
    }]
    setStorage("selectedKey", key)
    store.dispatch("setTabs", arr)
    setStorage("tabs", JSON.stringify(arr))
    store.dispatch("setIframeUrl", key)
}
let getNewSum = () => {
    let n = news.value
    if (!n.new) {
        return 0
    }
    let sum = 0
    for (let i in n) {
        if (i !== "new") {
            sum += n[i]
        }
    }
    return sum
}
const setStorage = (key, value) => {
    sessionStorage.setItem(key, value)
}
const changeAccount = (e) => {
    e.preventDefault();
    window.open("https://365.kdocs.cn/latest", "_blank")
}
let notice_list = computed(() => store.state.home.notice)

let selectedKey = ref("")
watch(tabs, (newVal) => {
    let sessKey = sessionStorage.getItem("selectedKey") ? sessionStorage.getItem("selectedKey") : null
    let defaultKey = newVal && newVal.length > 0 ? newVal[0].key : null
    selectedKey.value = sessKey || defaultKey
})
const changeFrameUrl = (e) => {
    router.push('/home')
    store.dispatch("setIframeUrl", e)
    sessionStorage.setItem("selectedKey", e)
    selectedKey.value = e
}
const getNews = () => {
    let user = localStorage.getItem("token") ? decrypto(localStorage.getItem("token")) : null
    let userid = user ? user.userid : ""
    let userInfo = { userid }
    post("/api/getJSON", { url: process.env.VUE_APP_NEWS_API, token: process.env.VUE_APP_API_TOKEN, params: { ...userInfo } }).then(res => {
        store.dispatch("setNews", res && res.data ? res.data : {})
        // console.log(res);
    })
}
const getDevice = () => {
    let width = window.innerWidth
    if (width < 768) {
        device.value = true
    } else {
        device.value = false
    }
}
onMounted(() => {
    getDevice()
    let menu = localStorage.getItem("head")
    if (menu) {
        store.dispatch("setMenu", JSON.parse(menu))
    }
    post("/api/getNotice", { url: process.env.VUE_APP_NOTICE_API, token: process.env.VUE_APP_API_TOKEN }).then(res => {
        store.dispatch("setNotice", res && res.data && res.data.length > 0 ? res.data : [])
    })
    getNews()

})
const checkIsIcon = (val, name) => {
    if (val.ht === name) {
        return true
    } else {
        return false
    }
}
// 处理登陆
const ifLogin = computed(() => store.state.home.ifLogin)
watch(ifLogin, (newVal) => {
    if (newVal === false) {
    }
})
const userInfo = computed(() => store.state.home.userInfo)

const reLogin = () => {
    localStorage.clear("token")
    store.dispatch("getIfLogin", false)
    router.push("/login")
    store.dispatch("setTodo", false)
}



</script>
<style scoped>
@media screen and (max-width:768px) {
    .cont-carousel {
        display: none;
    }

    .appMenu {
        display: block;
    }

    .bread {
        padding: 0 16px;
        position: fixed;
        top: var(--menu-head-height);
        left: 82px;
        /* display: flex;
    align-items: center; */
        background-color: #fff;
        height: var(--menu-head-height);
        width: calc(100% - 70px);
        overflow-y: hidden;
        font-size: 14px;
    }

    .logOut {
        position: fixed;
        top: 0;
        right: 15px !important;
    }

    .appMenuBtn {
        position: fixed;
        top: calc(var(--menu-head-height) - 6px);
        left: 0;
        height: calc(var(--menu-head-height) + 6px);
        background-color: #fff;
        font-size: 14px;
        padding: 5px 15px 5px 18px;
    }
}

@media screen and (min-width:768px) {
    .appMenu {
        display: none;
    }

    .bread {
        padding: 0 15px;
        position: fixed;
        top: var(--menu-head-height);
        left: calc(var(--menu-width));
        /* display: flex;
    align-items: center; */
        background-color: #fff;
        height: var(--menu-head-height);
        width: 100%;
        overflow-y: hidden;
    }
}

.slide-head-enter-active {
    transition: all 0.3s ease-out;
}

.slide-head-leave-active {
    transition: all .7s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-head-enter-from,
.slide-head-leave-to {
    /* transform: translateY(-20px); */
    opacity: 0;
}



.logOut {
    position: fixed;
    top: 0;
    right: 35px;
}

.null_content {
    position: fixed;
    left: var(--menu-width);
    top: 0;
    width: 100%;
    height: var(--menu-head-height);
    border-bottom: 1px solid #f0f0f0;
    background-color: #fff;
}

.notice {
    padding: 15px;
}

#iframe_box {
    width: 418px;
    height: 423px;
}

.confirm_login {
    width: 354px;
    height: 484px;
    margin: auto;
}

#wps_qrcode {
    position: relative;
    width: 100%;
    height: 500px;
    margin: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rqimg {
    width: 300px;
    height: 300px;
}

.reLogin {
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.headicon {
    font-size: 21px;
}

.headiconbox {
    display: flex;
    align-items: center;
}
</style>