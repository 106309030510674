import CryptoJS from 'crypto-js';
import store from "@/store/index"
import router from "@/router/index"

const ifLoginfn = (l = {}) => {
    return new Promise((n, d) => {
        let r = "https://account.kdocs.cn/api/v3/islogin";
        l.callbackName = l.callbackName || "callback";
        try {
            let c = !1;
            const t = `jsonp_${Date.now()}_${Math.ceil(Math.random() * 1e5)}`
                , e = document.createElement("object")
                , o = () => {
                    var a;
                    delete window[t],
                        (a = e.parentNode) == null || a.removeChild(e)
                }
                ;
            window[t] = a => {
                o(),
                    c = !0,
                    n(1)
            }
                ;
            const i = `${r}${r.includes("?") ? "&" : "?"}${t}=${t}`;
            e.data = i,
                e.onerror = a => {
                    o(),
                        n(0)
                }
                ,
                e.onload = a => {
                    c || (o(),
                        n(1))
                }
                ,
                document.body.appendChild(e)
        } catch (c) {
            d(c)
        }
    }
    )
}
const decrypto = (secret) => {
    var bytes = CryptoJS.AES.decrypt(secret, "EncryptionKey");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData
}

// 定时函数
const setTimer = (time) => {
    console.log(time);
    let timer = null
    const inputTime = new Date(time.replace(/-/g, '/'));
    const inputTimestamp = inputTime.getTime();
    timer = setInterval(() => {
        const now = Date.now();
        // console.log(now);
        if (now * 1 >= inputTimestamp * 1) {
            clearInterval(timer)
            // console.log("end");
            // store.dispatch("setUpdate", true)
            // router.push("/")
        }
    }, 1000)
}
const cont = () => {
    // let ws = new WebSocket('wss://cloud.znmq.cn/qywx/ws');
    let ws = new WebSocket(`${process.env.VUE_APP_WEBSOCKET}`);
    ws.onopen = () => {
        let user = localStorage.getItem("token") ? decrypto(localStorage.getItem("token")) : null
        let userid = user ? user.userid + "" : ""
        let userInfo = { userid }
        ws.send(JSON.stringify({ ...userInfo }))
        // store.dispatch("setNews", store.state.head.news ? store.state.head.news : {})
        // console.log("open");
    }
    ws.onmessage = (msg) => {
        let result = JSON.parse(msg.data)
        let data
        switch (result.stage) {
            case 0:
                break;
            case 1:
                data = result.data
                setTimer(data.interval)
                store.dispatch("setTime", data.interval)
                store.dispatch("setUpdate", false)
                router.push("/update")
                break;
            case 2:
                store.dispatch("setUpdate", true)
                let path = router.currentRoute.value.fullPath
                if (path === "/update") {
                    router.push("/")
                }
                break;
            case 5:
                result.data && store.dispatch("setNotice", result.data)
                break;
            case 6:
                result.data && store.dispatch("setNews", result.data)
                break;
        }
    };
    ws.onclose = () => {
        setTimeout(function () {
            cont()
        }, 1500)
        // console.log("close");
        // setTimeout(conectToSocket(), 2000)
    }
    ws.onerror = (error) => {
        console.error("WebSocket error:", error);
    };
}
function conectToSocket() {
    return new Promise((resolve, reject) => {
        cont()
        resolve(1)
    })

}
const getUrlSearch = (name) => {
    // 未传参，返回空
    if (!name) return null;
    // 查询参数：先通过search取值，如果取不到就通过hash来取
    var after = window.location.search;
    after = after.substr(1) || window.location.hash.split('?')[1];
    // 地址栏URL没有查询参数，返回空
    if (!after) return null;
    // 如果查询参数中没有"name"，返回空
    if (after.indexOf(name) === -1) return null;

    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
    // 当地址栏参数存在中文时，需要解码，不然会乱码
    var r = decodeURI(after).match(reg);
    // 如果url中"name"没有值，返回空
    if (!r) return null;

    return r[2];

}
export {
    ifLoginfn, decrypto, setTimer, conectToSocket, getUrlSearch
}