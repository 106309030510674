export default {
    namespace: "true",
    state() {
        return {
            isHome: false,
            toDo: false,
            menu: [],
            inline_menu: [],
            news: {}
        }
    },
    actions: {
        setNews({ commit }, val) {
            commit("SETNEWS", val)
        },
        setInlineMenu({ commit }, val) {
            commit("SETINLINEMENU", val)
        },
        setMenu({ commit }, val) {
            commit("SETMENU", val)
        },
        setIsHome({ commit }, val) {
            commit("SETISHOME", val)
        },
        setTodo({ commit }, val) {
            commit("SETTODO", val)
        }
    },
    mutations: {
        SETNEWS(state, val) {
            state.news = val
        },
        SETINLINEMENU(state, val) {
            state.inline_menu = val
        },
        SETMENU(state, val) {
            state.menu = val
        },
        SETTODO(state, val) {
            state.toDo = val
        },
        SETISHOME(state, val) {
            state.isHome = val
        }
    }

}