import request from "./request"

const get = (url, params) => {
    const config = {
        method: 'get',
        url: url
    }
    if (params) config.params = params
    return request(config)
}
const post = (url, params) => {
    const config = {
        method: 'post',
        url: url,
    }
    if (params) config.data = params
    return request(config)
}

export {
    get, post
}

