export default {
    namespace: "true",
    state() {
        return {

        }
    },
    actions: {

    },
    mutations: {

    }

}