<template>
    <div class="menu_head">
        <div class="logo">
            <img v-if="!collapsed" src="../images/mq.svg" alt="">
            <img style="margin-left: 6px;" v-else src="../images/中南集团LOGO（转曲）-01.svg" alt="">
        </div>
    </div>
    <div class="menu_ls">
        <a-menu @openChange="onOpenChange" :openKeys="state.openKeys" v-model:selectedKeys="state.selectedKeys"
            :inline-collapsed="collapsed" :items="items.menu" mode="inline" @click="handleClick">
        </a-menu>
    </div>
    <div class="collapsed_btn">
        <a-tooltip v-if="collapsed" placement="right">
            <template #title>
                <span>展开</span>
            </template>
            <MenuUnfoldOutlined @click="changeCollapsed" />
        </a-tooltip>
        <a-tooltip v-else placement="right">
            <template #title>
                <span>收起</span>
            </template>
            <MenuFoldOutlined @click="changeCollapsed" />
        </a-tooltip>

    </div>
    <a-drawer title=" " placement="left" :open="appMenu" @close="appMenuClose">
        <a-menu @openChange="onOpenChange" :openKeys="state.openKeys" v-model:selectedKeys="state.selectedKeys"
            :inline-collapsed="collapsed" :items="items.menu" mode="inline" @click="handleClick">
        </a-menu>
    </a-drawer>
</template>
<script setup>
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { useStore } from "vuex"
import { useRouter } from 'vue-router';
import { ifLoginfn, decrypto } from "../apis/index"
import { post } from "@/utils/http"
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons-vue"
import { menu_list } from "@/apis/menu_icon"
import CryptoJS from 'crypto-js';

const router = useRouter()
const store = useStore()
let items = reactive({
    menu: []
})
let collapsed = ref(false)
const changeCollapsed = () => {
    collapsed.value = !collapsed.value
    localStorage.setItem("collapsed", collapsed.value ? 1 : 0)
    switch (collapsed.value) {
        case true:
            document.documentElement.style.setProperty('--menu-width', 70 + 'px');
            break;
        case false:
            document.documentElement.style.setProperty('--menu-width', 210 + 'px');
            break
    }
}
const appMenu = computed(() => store.state.menu.appMenu)
const appMenuClose = () => {
    store.dispatch("setAppMenu", false)
}

const state = reactive({
    rootSubmenuKeys: [],
    openKeys: [],
    selectedKeys: ["workbench"]
})
// let collapsed = ref(true)
const isTopLevelDomain = (url) => {
    // 提取网址的域名部分
    let domain = new URL(url).hostname;

    // 判断顶级域名是否是 "365.kdocs.cn"
    return domain === '365.kdocs.cn' || domain === 'f.kdocs.cn';
}
watch(store.state.home, (newVal) => {
    let new_url = newVal.iframe_url
    try {
        let isHtmC = isTopLevelDomain(new_url)
        if (isHtmC) {
            store.dispatch("setHtm", true)
        } else {
            store.dispatch("setHtm", false)
        }
    } catch (error) {
        store.dispatch("setHtm", false)
    }
})
const handleClick = (e) => {
    // console.log(e);
    state.selectedKeys = [e.key]
    setStorage("selectedKey", e.key)
    setStorage("tabs", e.item.tabs ? JSON.stringify(e.item.tabs) : JSON.stringify([]))
    store.dispatch("setTodo", false)
    appMenuClose()
    switch (e.key) {
        case "workbench":
            router.push("/workbench")
            store.dispatch("setTabs", [])
            break;
        case "project":
            router.push("/proj")
            store.dispatch("setTabs", [])
            break;
        default:
            document.title = e.item.title
            setStorage("title", e.item.title)
            let cur_route = router.currentRoute.value.fullPath
            cur_route !== "/home" && router.push("/home")
            store.dispatch("setIframeUrl", (e.item.tabs && e.item.tabs.length > 0) ? e.item.tabs[0].key : e.key)
            // let home = [{ key: "https://365.kdocs.cn/latest", label: "首页" }]
            // let tabs = home.concat(e.item.tabs)
            store.dispatch("setTabs", e.item.tabs)
            ifLoginfn().then(res => {
                if (res * 1 === 0) {
                    router.push("/login")
                    localStorage.clear("token")
                    store.dispatch("getIfLogin", false)
                }
            })
    }
}
const setStorage = (key, value) => {
    sessionStorage.setItem(key, value)
}

const onOpenChange = openKeys => {
    // console.log(openKeys, state.openKeys, state.rootSubmenuKeys);
    const latestOpenKey = openKeys.find(key => state.openKeys.indexOf(key) === -1);
    if (state.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        state.openKeys = openKeys;
        setStorage("openKeys", state.openKeys)
    } else {
        state.openKeys = latestOpenKey ? [latestOpenKey] : [];
        setStorage("openKeys", state.openKeys)
    }
};

const getMemery = () => {
    let cur_route = router.currentRoute.value.fullPath
    let memery_route = sessionStorage.getItem("route")
    if (memery_route && memery_route !== cur_route) {
        router.replace(memery_route)
    }
    // console.log(memery_route);
    if (memery_route === "/home") {
        store.dispatch("setIframeUrl", sessionStorage.getItem("selectedKey") ? sessionStorage.getItem("selectedKey") : "")
        store.dispatch("setTabs", sessionStorage.getItem("tabs") ? JSON.parse(sessionStorage.getItem("tabs")) : [])
    }
    state.openKeys = [sessionStorage.getItem("openKeys") ? sessionStorage.getItem("openKeys") : ""]
    state.selectedKeys = [sessionStorage.getItem("selectedKey") ? sessionStorage.getItem("selectedKey") : ""]
    document.title = sessionStorage.getItem("title") ? sessionStorage.getItem("title") : "工作台"
    collapsed.value = localStorage.getItem("collapsed") * 1 === 1 ? true : false
    switch (collapsed.value) {
        case true:
            document.documentElement.style.setProperty('--menu-width', 70 + 'px');
            break;
        case false:
            document.documentElement.style.setProperty('--menu-width', 210 + 'px');
            break
    }
}
const changeKey = (arr) => {
    if (arr && arr.length > 0) {
        let result = []
        for (let i = 0; i < arr.length; i++) {
            result.push(arr[i].key)
        }
        return result
    } else {
        return []
    }

}
const formatMenuList = (list) => {
    list.map(item => {
        if (item.ht && item.ht !== "") {
            item.icon = menu_list[item.ht]
        }
    })
    return list
}
onMounted(() => {
    items.menu = localStorage.getItem("menu") ? formatMenuList(JSON.parse(localStorage.getItem("menu"))) : []
    state.rootSubmenuKeys = changeKey(items.menu)
    let user = localStorage.getItem("token") ? decrypto(localStorage.getItem("token")) : null
    let userid = user ? user.userid : ""
    let userInfo = { userid }
    getMemery()
    post("/api/getJSON", { url: process.env.VUE_APP_MENU_API, token: process.env.VUE_APP_API_TOKEN, params: { ...userInfo } },).then(res => {
        // if (res.code === 200) {
        if (res.data && res.code !== 500) {
            let data = res.data
            let status = data.status
            let list = data.msg
            if (status) {
                user.role = data.role
                user.dept = data.dept
                localStorage.setItem("head", JSON.stringify(data.waiting))
                store.dispatch("setMenu", data.waiting)
                let token = CryptoJS.AES.encrypt(JSON.stringify(user), "EncryptionKey").toString()
                localStorage.setItem("token", token)
                store.dispatch("setUserInfo", user)
                let workbench = [{ key: "workbench", label: "工作台", ht: "HomeOutlined", }]
                // let project = [{ key: "project", label: "项目中心", ht: "ProjectOutlined", }]
                // workbench = workbench.concat(project)
                list = workbench.concat(list)
                items.menu = formatMenuList(list)
                localStorage.setItem("menu", JSON.stringify(list))
            } else {
                console.log("未找到该成员");
                localStorage.setItem("menu", JSON.stringify([]))
                localStorage.setItem("tabs", JSON.stringify([]))
                items.menu = []
                store.dispatch("setTabs", [])
                store.dispatch("setNotice", [])
            }
        }
        // }
    })
    setTimeout(function () {
        getMemery()
    }, 1)
})
</script>

<style>
@media screen and (max-width:768px) {
    .collapsed_btn {
        display: none;
    }

    .menu_ls {
        display: none !important;
    }

    .logo {
        margin-right: 25px;
    }

}

.menu_head {
    width: 100%;
    height: var(--menu-head-height);
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;
}

.logo {
    width: var(--menu-width);
    height: var(--menu-head-height);
    line-height: var(--menu-head-height);
    text-align: center;
    /* margin-right: 25px; */
}

.logo>img {
    width: var(--menu-width);
    height: 35px;
}

.close_btn {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 39px;
}

.menu_ls {
    width: 100%;
    display: inline-block;
    /* height: calc(100vh -58px - var(--menu-head-height)); */
    height: calc(100vh - 58px - 46px);
    overflow-x: hidden;
    overflow-y: auto;
    border-right: 1px solid #f0f0f0;
}

.collapsed_btn {
    position: fixed;
    bottom: 0;
    width: var(--menu-width);
    height: 54px;
    background-color: #fff;
    text-align: right;
    padding: 14px 15px 0 0;
    border-top: 1px solid #eaeaea;
    border-right: 1px solid #f0f0f0;
}
</style>