import { createRouter, createWebHistory } from 'vue-router'

// 使用路由懒加载
const routes = [
    {
        path: '/',
        component: () => import("../views/Workbench"),
        redirect: "/workbench",
        meta: { title: "工作台" }
    },
    {
        path: '/login',
        component: () => import("../views/Login.vue"),
        meta: { title: "登录" }
    },
    {
        path: '/home',
        component: () => import("../views/Home.vue"),
    },
    {
        path: '/calendar',
        component: () => import("../views/Calendar.vue"),
        meta: { title: "日程" }
    },
    {
        path: '/workbench',
        component: () => import("../views/Workbench.vue"),
        meta: { title: "工作台" }
    },
    {
        path: '/test',
        component: () => import("../views/Test.vue"),
        meta: { title: "测试" }
    },
    {
        path: '/update',
        component: () => import("../views/Update.vue"),
        meta: { title: "维护中" }
    },
    {
        path: '/pages',
        component: () => import("../views/IframePages.vue"),
        meta: { title: "中南幕墙" }
    },
    {
        path: '/proj',
        component: () => import("../views/Project.vue"),
        meta: { title: "项目中心" }
    },
    {
        path: '/download',
        component: () => import("../views/DownLoad.vue"),
        meta: { title: "下载" }
    }

]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router